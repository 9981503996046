/**
 * Railsとの連携に便利なヘルパー関数を集めたモジュール
 */
import {
  ApiResponse,
  BaseAPI,
  Configuration,
  HTTPHeaders,
  HTTPQuery,
  InitOverrideFunction,
  VoidApiResponse,
} from '@/api/runtime';
import {
  ServerError,
  ServerErrorFromJSONTyped,
  ValidationErrorFromJSONTyped,
  WorkInstructionBundle,
} from '@/api/models';
import dayjs from 'dayjs';

type Employee = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  name: string;
  isAdmin: boolean;
  group: {
    id: number;
    name: string;
    depotId: number;
  };
};

/**
 * 対象データに対してログインユーザーが操作可能かを判定する
 * 対象データの状態判定をWorkInstructionを基点に行う場合はこちらを使用する
 * @param hasPermission 必要な権限を保持しているかどうか
 * @param workInstruction 対象データとなる作業指示、もしくは対象データの親となる作業指示。作業指示に関連する制約がない場合はは指定しない。
 *        作業指示に関連する制約については[業務ルール](https://sjc-developer-team.esa.io/posts/2786#異なる拠点のデータ登録・更新が可能となるケース)を参照
 * @param ignoreLock 作業指示のロック状態を無視するかどうか
 *
 * @returns 操作可能な場合はtrue、それ以外はfalse
 */
export function isAllowedWithWorkInstruction(
  hasPermission: boolean,
  workInstruction?: WorkInstructionBundle,
  ignoreLock: boolean = false
) {
  return isAllowed(
    hasPermission,
    workInstruction?.depotId,
    workInstruction?.partnerDepotId,
    workInstruction?.isLocked ?? false,
    ignoreLock
  );
}

/**
 * 対象データに対してログインユーザーが操作可能かを判定する共通判定処理
 * @param hasPermission 必要な権限を保持しているかどうか
 * @param targetBelongsDepotId 対象データの親となる作業指示が所属する拠点ID
 * @param targetBelongsPartnerDepotId 対象データの親となる作業指示に設定された依頼先拠点ID
 * @param ignoreLock 作業指示のロック状態を無視するかどうか
 * @param isLocked 作業指示のロック状態
 *
 * @returns 操作可能な場合はtrue、それ以外はfalse
 */
export function isAllowed(
  hasPermission: boolean,
  targetBelongsDepotId?: number | null,
  targetBelongsPartnerDepotId?: number | null,
  isLocked?: boolean,
  ignoreLock?: boolean
) {
  const employee = getCurrentEmployee();
  if (!employee) {
    return false;
  }

  let isAllowedDepotCondition = true;
  if (targetBelongsDepotId || targetBelongsPartnerDepotId) {
    isAllowedDepotCondition = [
      targetBelongsDepotId,
      targetBelongsPartnerDepotId,
    ].includes(employee.group.depotId);
  }

  const isUnLocked = ignoreLock || !isLocked;

  return (
    isUnLocked &&
    (employee.isAdmin || (hasPermission && isAllowedDepotCondition))
  );
}

/**
 * JSONレスポンスをServerErrorに変換する
 * @param response response.headers.get('Content-Type')が'application/json'であることが前提
 * @returns ServerError
 */
function parseJson(response: Response): Promise<ServerError> {
  return response
    .json()
    .then((json) =>
      response.status === 422
        ? ValidationErrorFromJSONTyped(json, true)
        : ServerErrorFromJSONTyped(json, true)
    );
}
/**
 * textレスポンスをServerErrorに変換する
 * @param response response.headers.get('Content-Type')が'text/plain'であることが前提
 * @returns ServerError
 */
function parseText(response: Response): Promise<ServerError> {
  return response.text().then((text) => {
    return { code: response.status, message: text };
  });
}
/**
 * HTMLレスポンスをServerErrorに変換する
 * @param response response.headers.get('Content-Type')が'text/html'であることが前提
 * @returns ServerError
 */
function parseHtml(response: Response): Promise<ServerError> {
  return response.text().then((html) => {
    console.warn('HTMLレスポンスはサポートされていません。');
    console.debug(html);
    return {
      code: response.status,
      message: '想定外のエラーが発生しました。',
    };
  });
}
/**
 * 未対応のmime typeのレスポンスをServerErrorに変換する
 * @param response
 * @returns ServerError
 */
function defaultParser(response: Response): Promise<ServerError> {
  return Promise.resolve({
    code: response.status,
    message: response.statusText,
  });
}
/**
 * レスポンスに対応するパーサーを返す
 * @param response パースするレスポンス
 * @returns パーサー
 */
function getParser(response: Response) {
  const contentType = response.headers.get('Content-Type') || '';
  if (contentType.includes('application/json')) {
    return parseJson;
  } else if (contentType.includes('text/plain')) {
    return parseText;
  } else if (contentType.includes('text/html')) {
    return parseHtml;
  } else {
    return defaultParser;
  }
}
/**
 * CSRFトークを返す
 * @returns {string} CSRF token
 */
export function getCsrfToken() {
  return (
    document
      ?.querySelector('meta[name="csrf-token"]')
      ?.getAttribute('content') || ''
  );
}
export function querystring(params: HTTPQuery, prefix: string = ''): string {
  return Object.keys(params)
    .map((key) => querystringSingleKey(key, params[key], prefix))
    .filter((part) => part.length > 0)
    .join('&');
}

function querystringSingleKey(
  key: string,
  value:
    | string
    | number
    | null
    | undefined
    | boolean
    | Array<string | number | null | boolean>
    | Set<string | number | null | boolean>
    | HTTPQuery,
  keyPrefix: string = ''
): string {
  const fullKey = keyPrefix + (keyPrefix.length ? `[${key}]` : key);
  if (value instanceof Array) {
    if (value.length === 0) {
      return encodeURIComponent(fullKey);
    }

    const multiValue = value
      .map((singleValue) => encodeURIComponent(String(singleValue)))
      .join(`&${encodeURIComponent(`${fullKey}[]`)}=`);

    return `${encodeURIComponent(`${fullKey}[]`)}=${multiValue}`;
  }
  if (value instanceof Set) {
    const valueAsArray = Array.from(value);
    return querystringSingleKey(key, valueAsArray, keyPrefix);
  }
  if (value instanceof Date) {
    return `${encodeURIComponent(fullKey)}=${encodeURIComponent(
      value.toISOString()
    )}`;
  }
  if (dayjs.isDayjs(value)) {
    return `${encodeURIComponent(fullKey)}=${encodeURIComponent(
      value.format('YYYY-MM-DDTHH:mm:ssZ')
    )}`;
  }
  if (value instanceof Object) {
    return querystring(value as HTTPQuery, fullKey);
  }
  if (value === null) {
    return encodeURIComponent(fullKey);
  }
  if (value === undefined) {
    return '';
  }
  return `${encodeURIComponent(fullKey)}=${encodeURIComponent(String(value))}`;
}

/**
 * RailsへのAPIリクエストを生成する
 * @param api APIクラスを指定
 * @returns 生成したAPIクラス
 */
export function buildApi<T extends BaseAPI>(
  api: new (arg: Configuration) => T
) {
  const headers: HTTPHeaders = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'X-CSRF-Token': getCsrfToken(),
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'X-Requested-With': 'XMLHttpRequest',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Accept: 'application/json',
  };

  // querystringはrailsの配列に合わせた形で生成するため、デフォルトのものを上書きする
  return new api(
    new Configuration({
      headers: headers,
      queryParamsStringify: querystring,
      // NOTE: 今回はHTMLの取得先とAPIのベースパスが同じなので、ここで設定している。もし異なる場合は修正すること
      basePath: window.location.origin,
      // NOTE: renderと同じパスのためローカルキャッシュが使用され、jsonではなくhtmlが返ってくることがあるため、キャッシュを無効化する
      fetchApi: (input, init) => fetch(input, { ...init, cache: 'no-cache' }),
    })
  );
}
/**
 * RailsからのエラーをServerErrorに変換して返す
 * @param error FetchApiからのエラー
 * @returns ServerError
 */
export async function getServerError(error: any): Promise<ServerError> {
  // ネットワークエラーでresponseが設定されていない場合を考慮する
  if (!error?.response?.status) {
    console.warn(error);
    return { code: 0, message: 'ネットワークに問題が発生しました。' };
  }
  const response: Response = error.response;
  const parser = getParser(response);
  if (parser) {
    return parser(response).catch((e) => {
      console.error(e);
      return {
        code: response.status,
        message: '想定外のエラーが発生しました。',
      };
    });
  } else {
    return Promise.resolve({
      code: response.status,
      message: response.statusText,
    });
  }
}
/**
 * HTML要素に埋め込まれているidを返す
 * NOTE: この関数は固定の要素に固定のdata属性が埋め込まれていることが前提
 * TODO: Railsのlayoutファイルで共通化したい
 * @param resourceName リソース名を指定する指定されない場合はvite_helper.rbのto_client_resource_id_tagでresource_nameを指定しない場合に対応
 *
 * @returns {number} ID
 */
export function getIdFromServer(resourceName: string = '') {
  return parseInt(
    document
      .getElementById(`fukuma-resource-${resourceName}-id`)
      ?.getAttribute('data-id') || '0'
  );
}
export type ServerMessage = {
  status: 'success' | 'error' | 'warning' | 'info';
  message: string;
};

/**
 * HTML要素に埋め込まれているメッセージを返す
 * NOTE: この関数は固定の要素に固定のdata属性が埋め込まれていることが前提
 * TODO: Railsのlayoutファイルで共通化したい
 * @returns {ServerMessage} サーバーからのメッセージ
 */
export function getMessageFromServer(): ServerMessage {
  return {
    status:
      (document
        .getElementById('fukuma-server-message')
        ?.getAttribute('data-status') as
        | 'success'
        | 'error'
        | 'warning'
        | 'info') || 'info',
    message:
      document
        .getElementById('fukuma-server-message')
        ?.getAttribute('data-message') || '',
  };
}

/**
 * HTML要素に埋め込まれているログイン社員情報を返す
 * NOTE: この関数は固定の要素に固定のdata属性が埋め込まれていることが前提
 * @returns {Employee} ログイン中の社員情報
 */
export function getCurrentEmployee(): Employee | undefined {
  const jsonString = document
    .getElementById('fukuma-current-employee')
    ?.getAttribute('data-current-employee');
  if (!jsonString) {
    return;
  }

  try {
    return JSON.parse(jsonString)?.employee;
  } catch (e) {
    console.error(e);
  }
}

/**
 * HTML要素に埋め込まれている権限情報を返す
 * NOTE: この関数は固定の要素に固定のdata属性が埋め込まれていることが前提
 * @returns { [key: string]: boolean } ログイン中の社員の権限情報
 */
export function getPermissions(): { [key: string]: boolean } {
  const jsonString = document
    .getElementById('fukuma-current-permissions')
    ?.getAttribute('data-current-permissions');
  if (!jsonString) {
    return {};
  }

  try {
    return JSON.parse(jsonString);
  } catch (e) {
    console.error(e);
    return {};
  }
}

/**
 *DeviseApiのcreateSessionのパラメータがapplication/x-www-form-urlencodedであるためリクエストモデルが生成されず
 エラーとなるためここでサインアウト用に定義し直す
 */
export class DeviseApi extends BaseAPI {
  /**
   * ログアウト
   */
  async destroySessionRaw(
    initOverrides?: InitOverrideFunction
  ): Promise<ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/employees/sign_out`,
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new VoidApiResponse(response);
  }

  /**
   * ログアウト
   */
  async destroySession(initOverrides?: InitOverrideFunction): Promise<void> {
    await this.destroySessionRaw(initOverrides);
  }
}
